import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiPhotoshop,
} from "react-icons/di";
import {
  RiReactjsLine,
} from "react-icons/ri";
import {
  IoLogoJavascript,
} from "react-icons/io";
import {
  TbBrandVscode,
} from "react-icons/tb";
import {
  FaWindows,
  FaFigma,
} from "react-icons/fa";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaWindows />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandVscode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaFigma />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhotoshop />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IoLogoJavascript />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <RiReactjsLine />
      </Col>
    </Row>
  );
}

export default Techstack;
