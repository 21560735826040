import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/blogging.png";
import emotion from "../../Assets/Projects/crm.jpg";
import editor from "../../Assets/Projects/portfolio.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/donate.jpg";
import bitsOfCode from "../../Assets/Projects/calculator.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Boostim"
              description="From Figma Design to Code project "
              ghLink="https://github.com/aakrity17/Boostim-Design"
            // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Calculator"
              description="Calculator using html css and Javascript "
              ghLink="https://github.com/aakrity17/Calculator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Simple Portfolio Site"
              description="Portfolio site's of Friend"
              ghLink="https://github.com/aakrity17/Pradip_Portfolio_site"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Mini Blogging Application"
              description="Mini Blogging frontend design where implemented local for the temporary data store"
              ghLink="https://github.com/aakrity17/Mini-Blogging-APP-React"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Charity and Donation management system (Fundfusion)"
              description="Charity and Donation project during the 6th semester project"
              ghLink="https://github.com/aakrity17/FundFusion"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Car rental Management System"
              description="Car rental Management System During the 4th semester Project using VB.net"
              ghLink="https://github.com/aakrity17/Car-Rental-Management-System-Using-Vb.net"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
